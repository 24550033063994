import Vuex from 'vuex';
import { createLocalVue, shallowMount } from '@vue/test-utils';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

import PaymentMethodsHubListener from './PaymentMethodsHubListener';

jest.mock('vuex-composition-helpers/dist', () => ({
  useStore: () => ({
    getters: {
      'UserData/getUserData': {
        company: {
          uuid: 'test',
        },
        roles: ['default'],
      },
      'CSOperator/getCurrentCSOperator': {},
      'Profile/getCurrentProfile': {},
      'Profile/getProfileCollection': [],
    },
  }),
}));

jest.mock('./composables/PaymentMethodsHubComposable', () => ({
  PaymentMethodsHubComposable: () => ({
    getUtilsFromStoredProvider: () => ({
      redirectConfirmQuery: '3ds1confirm',
      redirectQuery: '3ds1createpaymentmethod',
    }),
    storage: {
      get: jest.fn().mockResolvedValue({
        resourceType: null,
        resourceUuid: null,
        reference: 'ref',
        providerUsed: 'Adyen/3.23.0',
      }),
      remove: jest.fn(),
      add: jest.fn(),
    },
    getProfileInfo: () => ({
      isBusinessProfile: false,
    }),
  }),
}));

const paymentMethodsStore = {
  namespaced: true,
  getters: {
    getDefaultPaymentMethodUUID: () => jest.fn().mockResolvedValue('A'),
    getPaymentMethodByUUID: () => jest.fn().mockResolvedValue('B'),
  },
};

const mockedModules = {
  [PaymentMethodsHubNameSpace]: paymentMethodsStore,
};

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store({
  modules: mockedModules,
});

let wrapper;

const mocks = {
  $route: {
    query: '?3ds1confirm',
    path: '',
    fullPath: '?3ds1confirm',
  },
  $router: {
    history: {
      current: {
        fullPath: '',
      },
    },
    push: () => ({
      catch: jest.fn(),
    }),
  },
  modals: {
    active: {
      isOpen: jest.fn(),
    },
  },
  locale: 'es-es',
};

describe('Given PaymentMethodsHubListener', () => {
  beforeEach(() => {
    jest.resetModules();

    wrapper = shallowMount(PaymentMethodsHubListener, {
      data() {
        return {
          modals: {
            active: {
              isOpen: false,
            },
          },
        };
      },
      localVue,
      store,
      mocks,
    });
  });

  describe('When the "checkRedirect" method is called', () => {
    describe('And the route has a matching query', () => {
      it('Then the "onFromRedirect" method is called', async () => {
        const onFromRedirectSpy = jest.spyOn(wrapper.vm, 'onFromRedirect');

        mocks.$route.query = { '3ds1confirm': null };

        await wrapper.vm.checkRedirect();

        expect(onFromRedirectSpy).toHaveBeenCalled();
      });
    });

    describe('And the route has not a matching query', () => {
      it('Then the "onFromRedirect" method is not called', async () => {
        const onFromRedirectSpy = jest.spyOn(wrapper.vm, 'onFromRedirect');

        mocks.$route.query = '';

        await wrapper.vm.checkRedirect();

        expect(onFromRedirectSpy).not.toHaveBeenCalled();
      });
    });
  });

  describe('When the "onResponse" is called', () => {
    describe('And the response is "authorised"', () => {
      it('Then the "on:payment-authorised" is emitted', async () => {
        const response = {
          status: 'authorised',
        };

        await wrapper.vm.onResponse(response);

        expect(wrapper.emitted('on:payment-authorised')[0][0]).toEqual(
          expect.objectContaining({
            status: 'authorised',
          }),
        );
      });
    });

    describe('And the response is "refused"', () => {
      it('Then the "on:payment-refused" is emitted', async () => {
        const response = {
          status: 'refused',
        };

        const origin = 'redirect';

        await wrapper.vm.onResponse(response, origin);

        expect(wrapper.emitted('on:payment-refused')[0][0]).toEqual(
          expect.objectContaining({
            status: 'refused',
          }),
        );
      });
    });
  });
});
